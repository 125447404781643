<div class="card full-height-xl has-invisible-content asset-availability-chart">
	<div class="card-header">
		<h2 class="mb-0 h5">
			<fa-icon [icon]="faChartColumn"></fa-icon> Key Asset Offline RM Work Orders
		</h2>
	</div>
	<div class="card-body d-flex flex-column">
		<ng-select [clearable]="false" (change)="onChangeDisplayType()" [(ngModel)]="selectedDisplayType"
			class="dropdown-select with-max-width flex-shrink-0 flex-grow-0 mb-3">
			<ng-option [value]="'1'">{{whiteLabelService.getSettings.assetAvailabilityKeyAssetsOffline}}</ng-option>
			<ng-option [value]="'2'">State</ng-option>
		</ng-select>
		<div [ngClass]="{'invisible': !loaded}" class="chart-container full-height flex-shrink-1 flex-grow-1">
			<chart class="chart-container" #keyAssetsChart [type]="chartConfig.type" [data]="chartConfig.data"
				[options]="chartConfig.options"></chart>
		</div>
		<div [ngClass]="{'invisible': !loaded}"
			class="total-data half-strong text-end text-sm-end mt-3 flex-shrink-0 flex-grow-0"><span
				class="visually-hide-selection">Total: </span><span>{{ total | number }}</span></div>

		<div *ngIf="!loaded"><span class="loader"><span class="loader-inner"><span class="left">Loading...</span><span
						class="right"></span></span></span></div>
	</div>
</div>