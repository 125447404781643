import {
	ChangeDetectorRef,
	Component,
	OnDestroy,
	OnInit
} from '@angular/core';
import {
	faInfo
} from '@fortawesome/free-solid-svg-icons';
import {
	IMyDateRangeModel
} from 'mydaterangepicker';
import { Observable, Subscription } from 'rxjs';
import { AuthService } from '../../services/auth.service';
import {
	StateService
} from '../../services/state.service';
import {
	WhiteLabelService
} from '../../services/white-label.service';
import { AssetOfflineSummaryResponseModel } from '../models/assetOfflineSummaryResponseModel';
import { AssetRequestListResponseModel } from '../models/assetRequestListResponseModel';
import {
	QueryOptionsModel
} from '../models/queryOptionsModel';
import { QueryOptionsPagingModel } from '../models/queryOptionsPagingModel';
import { AssetService } from '../services/asset.service';
import {
	WOFiltersService
} from '../services/wo-filters.service';
import {
	WorkOrderReportService
} from '../services/work-order-report.service';

@Component({
	selector: 'app-asset-availability-summary',
	templateUrl: './asset-availability-summary.component.html'
})
export class AssetAvailabilitySummaryComponent implements OnInit, OnDestroy {
	private subs: Subscription = new Subscription();
	ngOnDestroy() {
		this.subs.unsubscribe();
	}

	loaded: boolean;
	value1: number = 0;
	value2: number = 0;
	value3: number = 0;
	value4: number = 0;

	constructor(private ref: ChangeDetectorRef,
		private authService: AuthService,
		private whiteLabelService: WhiteLabelService,
		private stateService: StateService,
		public woFiltersService: WOFiltersService,
		private assetService: AssetService,
		private workOrderReportService: WorkOrderReportService) {

	}

	roles$: Observable<string[]>;
	ngOnInit() {
		this.loaded = false;
		this.roles$ = this.authService.getCurrentUserRoles;
		this.woFiltersService.loadData();
		this.subs.add(this.woFiltersService.getUpdateId.subscribe(value => {
			this.update(this.stateService.getCurrentDateRange(), value);
		}));
	}

	fillBody(body: QueryOptionsModel | QueryOptionsPagingModel, dateRange: IMyDateRangeModel): QueryOptionsModel | QueryOptionsPagingModel {
		body = this.woFiltersService.fillBody(body, dateRange);
		body.filter.assetTypeTags = ['Key'];
		return body;
	}


	summaryData: AssetOfflineSummaryResponseModel[] = [];

	update(dateRange: IMyDateRangeModel, updateId: string) {
		this.loaded = false;
		let body = this.fillBody(new QueryOptionsModel(), dateRange);

		this.subs.add(this.assetService.getAssetOfflineSummary(body)
			.subscribe(summaryData => {
				if (this.woFiltersService.getCurrentUpdateId() === updateId) {
					this.summaryData = summaryData;
					this.loadPagedData(updateId);
				}
			}));
	}

	private totalExport: number = -1;
	private dataExport: AssetRequestListResponseModel[];
	loadPagedData(updateId) {
		this.dataExport = [];
		this.totalExport = -1;
		let body = this.fillBody(new QueryOptionsPagingModel(), this.stateService.getCurrentDateRange());
		body.filter.overallStatuses = ['Open'];
		body.filter.assetTypeTags = ['Offline', 'Key'];
		this.loadPagedDataRequest(body, updateId);
	}

	loadPagedDataRequest(body: any, updateId) {
		const limit = 10000;
		body.paging = {
			limit: limit,
			currentPage: this.totalExport < 0 ? 1 : Math.round(this.dataExport.length / limit) + 1
		};
		this.subs.add(this.assetService.getAssetRequestList(body)
			.subscribe(data => {
				if (this.woFiltersService.getCurrentUpdateId() === updateId) {
					if (data.length > 0) {
						this.totalExport = data[0].totalCount;
						this.dataExport.push(...data);
					}
					if (this.dataExport.length >= this.totalExport) {
						this.value3 = 0;
						this.value4 = 0;
						if (this.dataExport.length > 0) {
							this.dataExport.forEach(element => {
								this.value3 += element.respondKPIFail;
								this.value4 += element.resolveKPIFail;
							});
						}

						this.value1 = 0;
						this.value2 = 0;
						this.summaryData.forEach(element => {
							this.value1 += element.offlineCount;
							this.value2 += element.assetCount;
						});
						this.value2 = this.value1 ? (100 * (this.value2 - this.value1) / this.value2) : 100;

						this.loaded = true;
						this.ref.detectChanges();
					} else {
						this.loadPagedDataRequest(body, updateId);
					}
				}
			})
		);
	}

	// Icons
	faInfo = faInfo;
}